var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (Object.keys(_vm.blogDetail).length)?_c('div',{staticClass:"blog-wrapper"},[_c('div',{staticClass:"blog-detail-wrapper"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"},domProps:{"innerHTML":_vm._s(_vm.postConfig.headerHtml)}}),_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":_vm.blogDetail.title}},[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-50",attrs:{"vertical-align":"center"}},[_c('b-avatar',{attrs:{"href":"javascript:void(0)","size":"42","src":_vm.blogDetail.User.profileImage
                    ? ("/uploads/profileimage/3/" + (_vm.blogDetail.User.profileImage))
                    : require('@/assets/images/avatars/avatar.jpeg')}})],1),_c('b-media-body',[_c('small',[_c('b-link',{staticClass:"text-body"},[_vm._v(" "+_vm._s(_vm.blogDetail.User.userName)+" ")]),_c('br'),_c('b-link',{staticClass:"text-body text-right"},[_vm._v(" "+_vm._s(_vm.convertGenName(_vm.blogDetail.User.GenType.genName))+" ")])],1),_c('small',{staticClass:"text-muted float-right"},[_vm._v(" "+_vm._s(_vm.blogDetail.createdAt.substring(0, 16))+" ")])])],1),_c('hr',{staticClass:"my-2"}),_c('div',{staticClass:"blog-content mt-2",attrs:{"id":"postContents"},domProps:{"innerHTML":_vm._s(_vm.blogDetail.contents)}})],1)],1),_c('b-col',{staticClass:"my-2",attrs:{"cols":"12"}},[(
            _vm.blogDetail.UserId === _vm.userData.id || _vm.userData.role === 'manager'
          )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"success","to":{
            name: 'post-edit',
            params: { menuid: _vm.blogDetail.MenuId, postid: _vm.blogDetail.id },
          }}},[_vm._v(" 수정 ")]):_vm._e(),(
            _vm.blogDetail.UserId === _vm.userData.id || _vm.userData.role === 'manager'
          )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"danger","type":"button"},on:{"click":_vm.deletePost}},[_vm._v(" 삭제 ")]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" 목록 ")])],1),_c('b-col',{attrs:{"cols":"12"},domProps:{"innerHTML":_vm._s(_vm.postConfig.footerHtml)}})],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }