<template>
  <div
    v-if="Object.keys(blogDetail).length"
    class="blog-wrapper"
  >
    <!-- content -->
    <div class="blog-detail-wrapper">
      <b-row>
        <b-col
          cols="12"
          v-html="postConfig.headerHtml"
        >
        </b-col>
        <!-- blogs -->
        <b-col cols="12">
          <b-card :title="blogDetail.title">
            <b-media no-body>
              <b-media-aside
                vertical-align="center"
                class="mr-50"
              >
                <b-avatar
                  href="javascript:void(0)"
                  size="42"
                  :src="
                    blogDetail.User.profileImage
                      ? `/uploads/profileimage/3/${blogDetail.User.profileImage}`
                      : require('@/assets/images/avatars/avatar.jpeg')
                  "
                />
              </b-media-aside>
              <b-media-body>
                <!-- <small class="text-muted mr-50"></small> -->
                <small>
                  <b-link class="text-body">
                    {{ blogDetail.User.userName }}
                  </b-link>
                  <br />
                  <b-link class="text-body text-right">
                    {{ convertGenName(blogDetail.User.GenType.genName) }}
                  </b-link>
                </small>
                <!-- <span class="text-muted ml-75 mr-50">|</span> -->
                <small class="text-muted float-right">
                  {{ blogDetail.createdAt.substring(0, 16) }}
                </small>
              </b-media-body>
            </b-media>
            <hr class="my-2" />

            <div
              class="blog-content mt-2"
              id="postContents"
              v-html="blogDetail.contents"
            />

            <!-- eslint-enable -->
          </b-card>
        </b-col>
        <!-- buttons -->
        <b-col
          cols="12"
          class="my-2"
        >
          <!-- 수정, 삭제-->
          <b-button
            v-if="
              blogDetail.UserId === userData.id || userData.role === 'manager'
            "
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mr-1"
            :to="{
              name: 'post-edit',
              params: { menuid: blogDetail.MenuId, postid: blogDetail.id },
            }"
          >
            수정
          </b-button>
          <b-button
            v-if="
              blogDetail.UserId === userData.id || userData.role === 'manager'
            "
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="danger"
            type="button"
            @click="deletePost"
          >
            삭제
          </b-button>
          <!-- 목록 -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="secondary"
            class="float-right"
            @click="$router.go(-1)"
          >
            목록
          </b-button>

          <!-- 목록 -->
        </b-col>
        <!--/ blogs -->
        <b-col
          cols="12"
          v-html="postConfig.footerHtml"
        >
        </b-col>
      </b-row>
      <!--/ blogs -->
    </div>

    <!--/ content -->
  </div>
</template>

<script>
  import {
    BFormInput,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BImg,
    BLink,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BRow,
    BCol,
    BBadge,
    BCardText,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormTextarea,
    BFormCheckbox,
    BButton,
  } from 'bootstrap-vue';
  import Ripple from 'vue-ripple-directive';
  import { kFormatter } from '@core/utils/filter';

  export default {
    components: {
      BFormInput,
      BMedia,
      BAvatar,
      BMediaAside,
      BMediaBody,
      BLink,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BInputGroup,
      BInputGroupAppend,
      BImg,
      BBadge,
      BCardText,
      BDropdown,
      BForm,
      BDropdownItem,
      BFormTextarea,
      BFormCheckbox,
      BButton,
    },
    directives: {
      Ripple,
    },
    data() {
      // data 는 기본적으로 reactive

      return {
        userData: JSON.parse(localStorage.getItem('userData')),
        search_query: '',
        blogDetail: [],
        postConfig: {},
      };
    },
    created() {
      this.getContent();
    },
    updated() {
      this.$nextTick(function () {
        //
      });
    },
    methods: {
      kFormatter,
      async getContent() {
        const postid = this.$route.params.postid;
        this.$http.get(`/post/${postid}`).then(res => {
          this.blogDetail = res.data.post;
          this.postConfig = res.data.config;

          document.querySelector('.content-header-title').textContent =
            res.data.config.menuName;
        });
      },
      async deletePost() {
        // const payload = {
        //   data: {
        //     postconfigid: this.postConfig.id,
        //     postid: this.blogDetail.id,
        //   },
        // };
        const PostConfigId = this.blogDetail.PostConfigId;
        const id = this.blogDetail.id;

        this.$http
          .delete('/post/delete', {
            data: {
              PostConfigId,
              id,
            },
          })
          .then(res => {
            this.$router.go(-1);
          });
      },
      convertGenName(genName) {
        return typeof genName === 'number' ? `${genName}기` : genName;
      },
    },
    watch: {
      $route: function () {
        this.getContent();
      },
    },
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-blog.scss';
</style>
